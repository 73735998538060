export const getColorForValue = (value) => {
	const cappedValue = Math.max(0, Math.min(value, 3));
	const redToGreen = 120 * (cappedValue / 3);

	let light = 50;

	if (redToGreen > 90) {
		light = light - 20 * (redToGreen / 120);
	} else if (redToGreen > 60) {
		light = light - 10 * (redToGreen / 120);
	} else if (redToGreen > 30) {
		light = light - 5 * (redToGreen / 120);
	}

	return `hsl(${redToGreen}, 100%, ${light}%)`;
};

/**
 * Takes string of format "YYYY/YYYY" and returns "YY/YY"
 * @param {string} seasonName
 * @returns "YY/YY "string
 */
export const seasonNameShortener = (seasonName) => {
	const [startYear, endYear] = seasonName.split("/");

	if (startYear && endYear) {
		const formattedStartYear = startYear?.slice(-2);
		const formattedEndYear = endYear?.slice(-2);
		// return formatted value
		return `${formattedStartYear}/${formattedEndYear}`;
	} else {
		return seasonName;
	}
};

export const EUR = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "EUR",
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
