import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { observer } from "mobx-react-lite";
import ClubKPIs from "./ClubKPIs";
import MarketValueAssessmentComparison from "./MarketValueAssessmentComparison/Marketvalueassessmentcomparison";
import MarketValueComparison from "./Marketvaluecomparison";
import PlayerMarketValueAssessment from "./Playermarketvalueassessment";
import PlayTimeDistribution from "./Playtimedistribution";
import Powerrank from "./Powerrank";
import TransferKPI from "./Transferkpi";
import TransferKPIComparison from "./Transferkpicomparison";
import TransferKPIRisk from "./Transferkpirisk";
import "./Dashboard.css";
import { toJS } from "mobx";
import { dashboardStore } from "../State/DashboardStore";
import { descriptions } from "../Util/chartDescriptions";
import ChartSection from "./components/ChartSection";

const chartsContainerStyle = {
	display: "flex",
	flexDirection: "column",
	gap: {
		xs: 2,
		lg: 4,
		xxl: 6,
	},
};

const Dashboard = observer(() => {
	const data = toJS(dashboardStore.data);

	const [selectedCard, setSelectedCard] = useState();

	useEffect(() => setSelectedCard("transferKPI"), []);

	const cardRef = useRef(null);

	const handleCardClick = (card) => {
		setSelectedCard(card);
		if (cardRef.current) {
			cardRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	};

	return (
		<>
			<div>
				<ClubKPIs
					cardsKPIData={data}
					selectedCard={selectedCard}
					onCardClick={handleCardClick}
				/>
			</div>
			<Typography variant="h2" ref={cardRef}>
				{selectedCard === "transferKPI" && "Transfer KPI"}
				{selectedCard === "squadValue" && "Average Squad Value"}
				{selectedCard === "powerRank" && "Power Rank"}
				{selectedCard === "playTimeDistribution" && "Age Distribution"}
				{selectedCard === "GVSO" && "Market value share per category"}
				{selectedCard === "transferKPIRiskLevel" && "Transfer KPI Risk"}
			</Typography>
			<Box sx={chartsContainerStyle}>
				{selectedCard === "transferKPI" &&
					(data?.KPI?.length > 0 ? (
						<>
							<ChartSection
								title={"Per player"}
								updateFrequency="after each league game"
								description={descriptions.transferKpi}
								notes={descriptions.transferKpiNotes}
							>
								<TransferKPI data={data.KPI} />
							</ChartSection>

							<ChartSection
								title="Club comparison"
								description={descriptions.transferKpiComparison}
								updateFrequency={"weekly"}
							>
								<TransferKPIComparison data={data.TK} />
							</ChartSection>
						</>
					) : (
						<CircularProgress />
					))}
				{selectedCard === "squadValue" &&
					(data?.MCV?.length > 0 ? (
						<ChartSection
							description={descriptions.marketValueComparison}
							updateFrequency={"weekly"}
							notes={descriptions.marketValueComparisonNotes}
						>
							<MarketValueComparison data={data.MCV} />
						</ChartSection>
					) : (
						<CircularProgress />
					))}
				{selectedCard === "powerRank" &&
					(data?.PR?.length > 0 ? (
						<ChartSection description={descriptions.powerRank} updateFrequency={"weekly"}>
							<Powerrank data={data.PR} />
						</ChartSection>
					) : (
						<CircularProgress />
					))}
				{selectedCard === "playTimeDistribution" &&
					(data?.PTD?.length > 0 ? (
						<ChartSection
							description={descriptions.playTimeDistribution}
							updateFrequency={"weekly"}
						>
							<PlayTimeDistribution data={data.PTD} />
						</ChartSection>
					) : (
						<CircularProgress />
					))}
				{selectedCard === "GVSO" &&
					(data?.PMA?.length > 0 ? (
						<>
							<ChartSection
								description={descriptions.playerMarketValueAssessment}
								updateFrequency={"weekly"}
							>
								<PlayerMarketValueAssessment data={data.PMA} />
							</ChartSection>
							<MarketValueAssessmentComparison data={data?.MCAC} />
						</>
					) : (
						<CircularProgress />
					))}
				{selectedCard === "transferKPIRiskLevel" &&
					(data?.TKR?.length > 0 ? (
						<ChartSection
							title="Squad distribution"
							description={descriptions.transferKpiRisk}
							updateFrequency={"weekly"}
							descriptionAbove={true}
						>
							<TransferKPIRisk data={data.TKR} comparisonData={data.TK} />
						</ChartSection>
					) : (
						<CircularProgress />
					))}
			</Box>
		</>
	);
});

export default Dashboard;
