import React, { useEffect, useState } from "react";
import {
	Box,
	CircularProgress,
	Paper,
	Popover,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import "../Style/am_style.css";
import HelpIcon from "@mui/icons-material/HelpOutline";

const TableHeadCell = styled(TableCell)(({ theme }) => ({
	color: theme.palette.primary.contrast,
	fontSize: "16px",
	fontWeight: "bold",
	cursor: "pointer",
}));

const TableBodyCell = styled(TableCell)(({ theme }) => ({
	fontSize: "14px",
}));

const headerContentStyle = {
	display: "flex",
	alignItems: "center",
	gap: "5px",
};

const popoverStyle = {
	minWidth: 300,
	pointerEvents: "none",
};

const CustomTable = ({ tableData, tableColumns }) => {
	const [anchorEls, setAnchorEls] = useState({}); // Track anchorEl for each column
	const [data, setData] = useState([]);

	useEffect(() => {
		setData(tableData);
	}, [tableData, tableColumns]);

	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");

	const handleSort = (column) => {
		const isAsc = orderBy === column && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(column);
		const sortedData = [...data].sort((a, b) => {
			if (a[column] < b[column]) return isAsc ? -1 : 1;
			if (a[column] > b[column]) return isAsc ? 1 : -1;
			return 0;
		});
		setData(sortedData);
	};

	const infoHoverHandlerOpen = (event, column) => {
		setAnchorEls((prev) => ({ ...prev, [column]: event.currentTarget }));
	};
	const infoHoverHandlerClose = (column) => {
		setAnchorEls((prev) => ({ ...prev, [column]: null }));
	};

	return (
		<>
			{data?.length > 0 ? (
				<TableContainer component={Paper} sx={{ overflowX: "auto", width: "100%" }}>
					<Table>
						<TableHead>
							<TableRow>
								{tableColumns.map((column) => (
									<TableHeadCell
										key={column.field}
										onClick={() => {
											if (!column.disableSort) {
												handleSort(column.field);
											}
										}}
										sx={
											column.disableSort
												? { cursor: "default", ...column.customStyle }
												: { cursor: "pointer", ...column.customStyle }
										}
									>
										{column.headerName}{" "}
										{column.headerInfo && (
											<>
												<span
													style={headerContentStyle}
													aria-owns={
														Boolean(anchorEls[column.field])
															? "mouse-over-popover"
															: undefined
													}
													aria-haspopup="true"
													onMouseEnter={(event) =>
														infoHoverHandlerOpen(event, column.field)
													}
													onMouseLeave={() => infoHoverHandlerClose(column.field)}
												>
													<HelpIcon fontSize="small" />
												</span>
												<Popover
													id="mouse-over-popover"
													sx={popoverStyle}
													open={Boolean(anchorEls[column.field])}
													anchorEl={anchorEls[column.field]}
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "center",
													}}
													transformOrigin={{
														vertical: "top",
														horizontal: "left",
													}}
													onClose={() => infoHoverHandlerClose(column.field)}
													disableRestoreFocus
												>
													<Typography variant="body2" sx={{ p: 1 }}>
														{column.headerInfo}
													</Typography>
												</Popover>
											</>
										)}
										{!column.disableSort && (
											<span>
												{orderBy === column.field ? (order === "asc" ? "↑" : "↓") : ""}
											</span>
										)}
									</TableHeadCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((row, rowIndex) => (
								<TableRow key={rowIndex}>
									{tableColumns.map((column) => (
										<TableBodyCell key={column.field} sx={column.customStyle ?? {}}>
											{column.customContent
												? column.customContent(row[column.field], row, rowIndex)
												: row[column.field]}
										</TableBodyCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<Box
					sx={{
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
					}}
				>
					<CircularProgress />
				</Box>
			)}
		</>
	);
};

export default CustomTable;
