import React, { useEffect } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/system";
import { observer } from "mobx-react-lite";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import FloatingButton from "../Components/FloatingButton/FloatingButton";
import { scrollToTop } from "../Components/FloatingButton/utilFunctions";
import { dashboardStore } from "../State/DashboardStore";
import { routes } from "../Util/routes";

const dashBoardWrapperStyle = {
	display: "flex",
	flexDirection: "column",
	gap: 4,
	pb: { xs: 1, md: 4, xl: 6 },
	px: { xs: 1, md: 4, xl: 6 },
	margin: "0 auto",
	maxWidth: "2000px",
};

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
	color: theme.palette.primary.main,
	"&.Mui-selected": {
		color: theme.palette.primary.main,
	},
	"&:hover": {
		color: theme.palette.primary.main,
	},
}));

// TODO: Här måste vi kolla entitlements.

const ClubOverview = observer(() => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const location = useLocation();
	const navigate = useNavigate();

	const getCurrentRoute = () => {
		if (location.pathname.includes(routes.am.club.matchReport.base)) {
			return routes.am.club.matchReport.pre;
		} else {
			return location.pathname;
		}
	};

	useEffect(() => {
		dashboardStore.fetchData();
	}, []);

	return (
		<div>
			<div
				style={{
					margin: "20px 30px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<ToggleButtonGroup
					color="primary"
					value={getCurrentRoute()}
					size={isSmallScreen ? "small" : "large"}
					exclusive
					onChange={(e, newValue) => {
						if (newValue) {
							navigate(newValue);
						}
					}}
					aria-label="Platform"
				>
					<CustomToggleButton value={routes.am.club.base}>Dashboard</CustomToggleButton>
					<CustomToggleButton value={routes.am.club.report}>Report</CustomToggleButton>
					<CustomToggleButton value={routes.am.club.performance}>
						Performance
					</CustomToggleButton>
					<CustomToggleButton value={routes.am.club.squad}>Squad</CustomToggleButton>
					<CustomToggleButton value={routes.am.club.matchReport.pre}>
						Match Report
					</CustomToggleButton>
				</ToggleButtonGroup>
			</div>
			<Box sx={dashBoardWrapperStyle}>
				{!dashboardStore.isLoading ? (
					<Outlet />
				) : (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "100vh",
						}}
					>
						<CircularProgress />
					</div>
				)}
			</Box>
			<FloatingButton
				onClickAction={scrollToTop}
				isVisible={false}
				iconName={ArrowUpwardIcon}
				toolTipText={"Scroll to top"}
			/>
		</div>
	);
});

export default ClubOverview;
