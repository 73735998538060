import { React, useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import Box from "@mui/material/Box";
import { Chart, registerables } from "chart.js";
import { color } from "../Style/theme";
import { EUR, seasonNameShortener } from "./utilFunctions";

Chart.register(...registerables);

const MarketValueComparison = (props) => {
	const [chartData, setChartData] = useState({ datasets: [] });
	const chartRef = useRef(null);
	const leagueMarketValueSumRef = useRef([]);

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: "nearest",
			axis: "x",
			intersect: false,
		},
		scales: {
			y: {
				type: "linear",
				display: true,
				position: "left",
				title: {
					display: true,
					text: "Market value (m €)",
				},
				ticks: {
					callback: function (value) {
						return value / 1000000;
					},
				},
			},
			yRight: {
				type: "linear",
				display: true,
				position: "right",
				title: {
					display: true,
					text: "Percent (%)",
				},
				grid: {
					drawOnChartArea: false,
				},
			},
			x: {
				ticks: {
					callback: function (value) {
						const scale = this;
						const season = scale.getLabelForValue(value);
						return seasonNameShortener(season);
					},
				},
			},
		},
		plugins: {
			legend: {
				position: "top",
				labels: {
					padding: 30,
					font: {
						size: 16,
					},
				},
			},
			watermark: {
				yAlign: "top",
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						const label = context.dataset.label || "";
						const value = context.raw;
						// Conditional formatting based on dataset index
						let formattedValue;
						if (context.datasetIndex === 0) {
							// Market value dataset
							formattedValue = EUR.format(value);
						} else if (context.datasetIndex === 1) {
							// Percentage dataset
							formattedValue = `${value.toFixed(1)}%`;
						} else {
							formattedValue = value; // Default format for other datasets
						}
						return `${label}: ${formattedValue}`;
					},
					footer: function (context) {
						// Access the local league market value for the current index
						const index = context[0].dataIndex;
						const leagueMarketValue = leagueMarketValueSumRef.current[index];
						return `Domestic Market Value: ${EUR.format(leagueMarketValue)}`;
					},
				},
			},
		},
	};

	const loadData = (jsonData) => {
		var data = jsonData.filter((item) => item.SeasonName);
		data.sort((a, b) => a.SeasonName.localeCompare(b.SeasonName));

		const seasons = data.map((item) => item.SeasonName);
		const marketValues = data.map((item) => item.ClubMarketValueSum);
		const domesticPercentages = data.map((item) => item.DomesticPercentage);
		const localLeagueMarketValueSum = data.map((item) => item.LocalLeagueMarketValueSum);

		leagueMarketValueSumRef.current = localLeagueMarketValueSum;

		const datasets = [
			{
				type: "bar",
				label: "Market value",
				data: marketValues,
				yAxisID: "y",
				backgroundColor: color.primary.main,
				order: 3,
			},
			{
				type: "line",
				label: "Percentage of domestic market value",
				data: domesticPercentages,
				yAxisID: "yRight",
				backgroundColor: color.secondary.main,
				borderColor: color.secondary.main,
				order: 2,
			},
		];
		setChartData({ labels: seasons, datasets: datasets });
	};

	useEffect(() => {
		loadData(props.data);
	}, [props.data]);

	/*
		Important note! Chart.js uses its parent container to update the canvas render and display sizes. 
		This method requires the container to be relatively positioned and 
		dedicated to the chart canvas only. Responsiveness can then be achieved by setting 
		relative values for the container size
	*/
	return (
		<Box
			sx={{
				position: "relative",
				width: "100%",
				height: "100%",
				maxHeight: { sx: "80vh", lg: "50vh" },
			}}
		>
			<Bar
				data={chartData}
				options={chartOptions}
				width={600}
				height={450}
				ref={chartRef}
			/>
		</Box>
	);
};
export default MarketValueComparison;
