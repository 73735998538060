import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useOutletContext, useSearchParams } from "react-router-dom";
import ExportPDF from "../Components/ExportPDF";
import { auth0Service } from "../Util/Auth0Client";
import useDebounce from "../Util/useDebounce";
import ChanceChart from "./ChanceChart";
import DefenceChart from "./DefenceChart";
import FlanksChart from "./FlanksChart";
import PassingsChart from "./PassingsChart";
import PossessionChart from "./PossessionChart";
import PostReportOverview from "./PostReportOverview";

const PostReportContainer = () => {
	const chartRefs = useRef([]);
	const [firstClubData, setFirstClubData] = useState([]);
	const [secondClubData, setSecondClubData] = useState([]);

	const [matchData, setMatchData] = useState(null);
	const [leagueAvg, setLeagueAvg] = useState({});
	const [postReportAvailable, setPostReportAvailable] = useState();
	const [isLoading, setIsLoading] = useState();
	const [defenceTableData, setDefenceTableData] = useState([]);
	const [error, setError] = useState(null);

	const { impersonationInfo } = useOutletContext();

	const [searchParams, setSearchParams] = useSearchParams();

	// debounce so we dont fetch directly
	const debouncedMatchId = useDebounce(searchParams.get("matchId"), 400);

	useEffect(() => {
		const fetchData = async (matchId) => {
			setIsLoading(true);
			setError(null);

			try {
				const token = await auth0Service.getToken();
				const header = { Authorization: `Bearer ${token}` };
				const response = await fetch(`/api/matchreport/post?matchId=${matchId}`, {
					headers: header,
					credentials: "include",
				});

				if (!response.ok) {
					throw new Error("Failed to fetch post match report data");
				}

				const postMatchReportData = await response.json();
				if (postMatchReportData?.PR?.length === 0) {
					setPostReportAvailable(false);
				} else {
					// console.table(postMatchReportData.LM[0])
					setMatchData(postMatchReportData.PR[0] || []);
					setLeagueAvg(postMatchReportData.LM || {});
					setPostReportAvailable(true);
				}
			} catch (error) {
				setError(error.message);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData(debouncedMatchId);
	}, [debouncedMatchId]);

	useEffect(() => {
		if (matchData?.length > 0) {
			const teamData =
				matchData[0]?.ClubGroup === impersonationInfo?.ClubGroup
					? matchData[0]
					: matchData[1] || {};
			const opponentData =
				matchData[0]?.ClubGroup === impersonationInfo?.ClubGroup
					? matchData[1]
					: matchData[0] || {};
			setFirstClubData(teamData);
			setSecondClubData(opponentData);
			const defenceTableData = [
				{
					team: teamData?.ClubGroup,
					fouls: parseFloat(teamData.Fouls)?.toFixed(2),
					yellowCards: parseFloat(teamData.YellowCards)?.toFixed(2),
					redCards: parseFloat(teamData.RedCards)?.toFixed(2),
				},
				{
					team: opponentData?.ClubGroup,
					fouls: parseFloat(opponentData.Fouls)?.toFixed(2),
					yellowCards: parseFloat(opponentData.YellowCards)?.toFixed(2),
					redCards: parseFloat(opponentData.RedCards)?.toFixed(2),
				},
				{
					team: "League average",
					fouls: leagueAvg.AvgFouls?.toFixed(2),
					yellowCards: leagueAvg.AvgYellowCards?.toFixed(2),
					redCards: leagueAvg.AvgRedCards?.toFixed(2),
				},
			];

			setDefenceTableData(defenceTableData);
		}
	}, [matchData, impersonationInfo?.ClubGroup]);

	if (isLoading) {
		return (
			<Box sx={{ textAlign: "center" }}>
				<CircularProgress />
			</Box>
		);
	}

	if (!postReportAvailable) {
		return (
			<Box sx={{ textAlign: "center" }}>
				<Typography variant="h4" gutterBottom sx={{ mt: 10, mb: 2 }}>
					Post-Report not Available Yet
				</Typography>
			</Box>
		);
	}

	return (
		<>
			{error && <div style={{ textAlign: "center" }}>Error: {error}</div>}

			<div className="custom-separator"></div>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					flex: 1,
					marginTop: "10px",
					marginRight: "50px",
				}}
			>
				<ExportPDF
					titles={[
						"General",
						"Possession",
						"Passing",
						"Defence",
						"Chance creation",
						"Flanks",
					]}
					coverTitle={"Post-Match Report ".concat(secondClubData?.ClubGroup || "")}
					refs={chartRefs}
				/>
			</div>
			<div className="chart-background">
				<h2 className="subtitle">General</h2>

				<div className="chart-container">
					<div ref={(el) => (chartRefs.current[0] = el)}>
						<PostReportOverview data={matchData} impersonationInfo={impersonationInfo} />
					</div>
				</div>
			</div>
			<div className="chart-background">
				<h2 className="subtitle">Possession</h2>

				<div className="chart-container">
					<div ref={(el) => (chartRefs.current[1] = el)} style={{ width: "100%" }}>
						<PossessionChart firstClubData={firstClubData} />
					</div>
				</div>
			</div>
			<div className="chart-background">
				<h2 className="subtitle">Passing</h2>

				<div className="chart-container">
					<div ref={(el) => (chartRefs.current[2] = el)}>
						<PassingsChart
							firstClubData={firstClubData}
							secondClubData={secondClubData}
						/>
					</div>
				</div>
			</div>
			<div className="chart-background">
				<h2 className="subtitle"> Defence </h2>
				<div className="chart-container">
					<div ref={(el) => (chartRefs.current[3] = el)}>
						<DefenceChart
							firstClubData={firstClubData}
							secondClubData={secondClubData}
							tableData={defenceTableData}
						/>
					</div>
				</div>
			</div>
			<div className="chart-background">
				<h2 className="subtitle"> Chance creation </h2>
				<div className="chart-container">
					<div ref={(el) => (chartRefs.current[4] = el)}>
						<ChanceChart
							firstClubData={firstClubData}
							secondClubData={secondClubData}
							leagueAvg={leagueAvg}
						/>
					</div>
				</div>
			</div>
			<div className="chart-background">
				<h2 className="subtitle"> Flanks </h2>
				<div className="chart-container">
					<div ref={(el) => (chartRefs.current[5] = el)}>
						<FlanksChart firstClubData={secondClubData} />
					</div>
				</div>
			</div>
		</>
	);
};

export default PostReportContainer;
