import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { dashboardStore } from "../State/DashboardStore";
import { auth0Service } from "../Util/Auth0Client";
import { season } from "../Util/season";

export default class ImpersonationStore {
	dropdownOptions = [];
	selectedOption = null;
	isLoading = false;
	error = null;

	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: "ImpersonationStore",
			properties: ["dropdownOptions", "selectedOption"],
			storage: window.localStorage,
		});
	}

	loadDropDownOptions = async () => {
		this.setLoading(true);
		this.setError(null);

		try {
			const token = await auth0Service.getToken();
			const headers = { Authorization: `Bearer ${token}` };
			const [optionsResponse, sessionResponse] = await Promise.all([
				fetch("/api/impersonation", { headers, credentials: "include" }),
				fetch("/api/getsessionclubid", { headers, credentials: "include" }),
			]);

			const optionsData = await optionsResponse.json();
			const sessionData = await sessionResponse.json();
			runInAction(() => {
				this.setDropdownOptions(optionsData);
				this.setSelectedOption(sessionData);
			});
		} catch (error) {
			runInAction(() => {
				this.setError(error.message);
				console.log(error);
			});
		} finally {
			runInAction(() => {
				this.setLoading(false);
			});
		}
	};

	handleChange = async (event, value) => {
		const newClubId = value.ClubId;
		runInAction(() => {
			this.selectedOption = value;
		});
		try {
			const token = await auth0Service.getToken();
			const headers = {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			};
			await fetch("/api/updatesessionclubid", {
				credentials: "include",
				method: "POST",
				headers,
				body: JSON.stringify({ ClubId: newClubId }),
			});
			await Promise.all([dashboardStore.fetchData(), season.fetchData()]);
		} catch (error) {
			runInAction(() => {
				this.setError(error.message);
			});
		}
	};

	setDropdownOptions(options) {
		this.dropdownOptions = options;
	}

	setSelectedOption(sessionData) {
		if (sessionData) {
			const initialOption = this.dropdownOptions.find(
				(option) => parseInt(option.ClubId) === parseInt(sessionData)
			);
			if (initialOption != null) {
				this.selectedOption = initialOption;
			} else {
				this.selectedOption = null;
			}
		} else if (this.dropdownOptions > 0) {
			this.selectedOption = this.dropdownOptions[0];
		}
	}

	setLoading(loading) {
		this.isLoading = loading;
	}

	setError(error) {
		this.error = error;
	}

	extractClubInfo() {
		return this.selectedOption;
	}

	extractClubId() {
		return this.selectedOption?.ClubId;
	}
}

export const impersonationStore = new ImpersonationStore();
