import React, { memo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Telegram from "@mui/icons-material/Telegram";
import { Box, Divider, styled, Tab, Tabs } from "@mui/material";
import { observer } from "mobx-react-lite";
import Addons from "../Chargebee/Addons";
import BillingHistory from "../Chargebee/BillingHistory";
import SubscriptionPlans from "../Chargebee/SubscriptionPlans";
import FloatingButton from "../Components/FloatingButton/FloatingButton";
import { FeedbackForm } from "../Components/FloatingButton/utilFunctions";
import { profileStore } from "../State/ProfileStore";
import PasswordResetForm from "./ResetPassword";

const CustomTab = styled(Tab)(({ theme }) => ({
	color: "#4A6877",
	fontFamily: "Montserrat, sans-serif",
	fontWeight: 600,
	fontSize: "14px",
	textTransform: "none",
	"&.Mui-selected": {
		color: "#23374C",
	},
	"&.Mui-disabled": {
		color: "#A9A9A9",
	},
	"&:hover": {
		color: "#23374C",
		opacity: 1,
	},
}));

const TabPanel = memo(function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
			style={{
				display: value === index ? "block" : "none",
				width: "100%",
				height: "100%",
				paddingTop: "50px",
				backgroundColor: "#F3F5F1",
			}}
		>
			{value === index && (
				<Box
					sx={{
						p: 3,
						display: "flex",
						justifyContent: "center",
						height: "100%",
					}}
				>
					{children}
				</Box>
			)}
		</div>
	);
});

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

const Profile = observer(() => {
	const { isLoading } = useAuth0();

	const [isFormVisible, setIsFormVisible] = useState(false);

	const toggleForm = () => {
		setIsFormVisible((prev) => !prev);
	};

	if (isLoading) {
		return <div>Loading ...</div>;
	}

	return (
		<>
			<Box
				sx={{
					display: "flex",
					height: "100%",
					backgroundColor: "#F3F5F1",
				}}
			>
				<Tabs
					orientation="vertical"
					aria-label="basic tabs example"
					value={profileStore.currentTabIndex}
					onChange={profileStore.handleTabChange}
					TabIndicatorProps={{
						style: {
							backgroundColor: "#23374C",
							width: "3px",
						},
					}}
					sx={{
						borderRight: 1,
						borderColor: "divider",
						width: "200px",
					}}
				>
					<CustomTab label="My subscription" {...a11yProps(0)} />
					<CustomTab label="Change Login details" {...a11yProps(1)} />
					<CustomTab label="Billing history" {...a11yProps(2)} />
				</Tabs>
				<Box
					sx={{
						flexGrow: 1,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						padding: "20px",
					}}
				>
					<TabPanel
						value={profileStore.currentTabIndex}
						index={0}
						sx={{
							width: "100%",
							height: "100%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "20px",
								width: "100%",
								alignItems: "stretch",
							}}
						>
							<SubscriptionPlans />
							<Divider sx={{ width: "100%" }} />

							<Addons />
						</Box>
					</TabPanel>

					<TabPanel value={profileStore.currentTabIndex} index={1}>
						<PasswordResetForm />
					</TabPanel>
					<TabPanel value={profileStore.currentTabIndex} index={2}>
						<BillingHistory />
					</TabPanel>
				</Box>
			</Box>
			<FloatingButton
				onClickAction={toggleForm}
				isVisible={true}
				iconName={Telegram}
				toolTipText={"Submit feedback!"}
			/>
			<FeedbackForm isFormVisible={isFormVisible} toggleForm={toggleForm} />
		</>
	);
});

export default Profile;
