import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { observer } from "mobx-react-lite";
import ExportPDF from "../Components/ExportPDF";
import { dashboardStore } from "../State/DashboardStore";
import { impersonationStore } from "../State/ImpersonationStore";
import { descriptions } from "../Util/chartDescriptions";
import ChartSection from "./components/ChartSection";
import MarketValueAssessmentComparison from "./MarketValueAssessmentComparison/Marketvalueassessmentcomparison";
import MarketValueComparison from "./Marketvaluecomparison";
import PlayerMarketValueAssessment from "./Playermarketvalueassessment";
import PlayTimeDistribution from "./Playtimedistribution";
import Powerrank from "./Powerrank";
import TransferKPI from "./Transferkpi";
import TransferKPIComparison from "./Transferkpicomparison";
import TransferKPIRisk from "./Transferkpirisk";
import "./Datapage.css";
import "./Dashboard.css";
import { toJS } from "mobx";

const Datapage = observer(() => {
	const data = toJS(dashboardStore.data);

	useEffect(() => {
		const clubInfo = impersonationStore.extractClubInfo();
		setClubName(clubInfo.ClubName);
	}, []);

	const chartRefs = useRef([]);
	const [clubName, setClubName] = useState([]);

	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					flex: 1,
					marginTop: "10px",
					marginRight: "50px",
				}}
			>
				<ExportPDF
					titles={[
						"Transfer KPI",
						"Transfer KPI comparision",
						"Power rank",
						"Average Squad value",
						"Age Distribution",
						"Market value share per category",
					]}
					coverTitle={clubName}
					refs={chartRefs}
				/>
			</div>

			{data?.KPI?.length > 0 ? (
				<div ref={(el) => (chartRefs.current[0] = el)}>
					<ChartSection
						title={"Transfer KPI"}
						updateFrequency="after each league game"
						description={descriptions.transferKpi}
						notes={descriptions.transferKpiNotes}
					>
						<TransferKPI data={data.KPI} />
					</ChartSection>
				</div>
			) : (
				<CircularProgress />
			)}

			{data?.TK?.length > 0 ? (
				<div ref={(el) => (chartRefs.current[1] = el)}>
					<ChartSection
						title="Transfer KPI comparision"
						description={descriptions.transferKpiComparison}
						updateFrequency={"weekly"}
					>
						<TransferKPIComparison data={data.TK} />
					</ChartSection>
				</div>
			) : (
				<CircularProgress />
			)}

			{data?.PR?.length > 0 ? (
				<div ref={(el) => (chartRefs.current[2] = el)}>
					<ChartSection
						title="Power rank"
						description={descriptions.powerRank}
						updateFrequency={"weekly"}
					>
						<Powerrank data={data.PR} />
					</ChartSection>
				</div>
			) : (
				<CircularProgress />
			)}

			{data?.MCV?.length > 0 ? (
				<div ref={(el) => (chartRefs.current[3] = el)}>
					<ChartSection
						title="Average Squad value"
						description={descriptions.marketValueComparison}
						notes={descriptions.marketValueComparisonNotes}
						updateFrequency={"weekly"}
					>
						<MarketValueComparison data={data.MCV} />
					</ChartSection>
				</div>
			) : (
				<CircularProgress />
			)}

			{data?.PTD?.length > 0 ? (
				<div ref={(el) => (chartRefs.current[4] = el)}>
					<ChartSection
						title="Age Distribution"
						description={descriptions.playTimeDistribution}
						updateFrequency={"weekly"}
					>
						<PlayTimeDistribution data={data.PTD} />
					</ChartSection>
				</div>
			) : (
				<CircularProgress />
			)}

			{data?.TKR?.length > 0 ? (
				<div ref={(el) => (chartRefs.current[5] = el)}>
					<ChartSection
						title="Transfer KPI Risk"
						description={descriptions.transferKpiRisk}
						updateFrequency={"weekly"}
						descriptionAbove={true}
					>
						<TransferKPIRisk data={data.TKR} comparisonData={data?.TK ?? []} />
					</ChartSection>
				</div>
			) : (
				<CircularProgress />
			)}

			{data?.PMA?.length > 0 ? (
				<div ref={(el) => (chartRefs.current[6] = el)}>
					<ChartSection
						title="Market value share per category"
						description={descriptions.playerMarketValueAssessment}
						updateFrequency={"weekly"}
					>
						<PlayerMarketValueAssessment data={data.PMA} />
					</ChartSection>
				</div>
			) : (
				<CircularProgress />
			)}

			{data?.MCAC?.length > 0 ? (
				<MarketValueAssessmentComparison
					data={data.MCAC}
					addChartRef={(el, index) => {
						chartRefs.current[7 + index] = el;
					}}
				/>
			) : (
				<CircularProgress />
			)}
		</>
	);
});

export default Datapage;
