import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	CircularProgress,
	styled,
	Typography,
} from "@mui/material";
import BasePlan from "../Logos/GoalunitLogo_NavyHorisontal.svg";
import CancelSubscription from "./CancelSubscription";

const SubscriptionPlans = () => {
	const [plans, setPlans] = useState([]);
	const [activeSubscription, setActiveSubscription] = useState([]);
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		const fetchSubscriptionplans = async () => {
			try {
				const token = await getAccessTokenSilently();
				const header = { Authorization: `Bearer ${token}` };
				const subscriptionplans = await fetch("/api/subscriptionplans", {
					headers: header,
					credentials: "include",
				});

				if (!subscriptionplans.ok) {
					throw new Error(`HTTP error! Status: ${subscriptionplans.status}`);
				}

				const plan_response = await subscriptionplans.json();
				const sortedPlans = plan_response.sort((a, b) => {
					const minPriceA = Math.min(...a.Prices.map((priceObj) => priceObj.price));
					const minPriceB = Math.min(...b.Prices.map((priceObj) => priceObj.price));

					return minPriceA - minPriceB;
				});

				setPlans(sortedPlans);
				setActiveSubscription(sortedPlans.filter((plan) => plan.Subscription.id !== ""));
			} catch (error) {
				console.log("Something went wrong", error);
			}
		};
		fetchSubscriptionplans();
	}, []);

	const handleCardClick = async (plan) => {
		if (plan.Subscription.id === "" || plan.Subscription.cancelled_at > 0) {
			try {
				const token = await getAccessTokenSilently();
				const header = { Authorization: `Bearer ${token}` };
				const body = JSON.stringify({ itemPriceId: plan.Prices[0].id });
				const response = await fetch("/api/upsertsubscription", {
					method: "POST",
					headers: header,
					credentials: "include",
					body: body,
				});
				const hostedpage_response = await response.json();
				localStorage.setItem("shouldStartPolling", "true");
				window.location.href = hostedpage_response.url;
			} catch (error) {
				console.log("Something went wrong", error);
			}
		}
	};

	return (
		<div>
			{plans.length > 0 ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							gap: 5,
							justifyContent: "center",
						}}
					>
						{plans.map((plan, index) => (
							<Box
								key={index}
								sx={{
									width: "30%",
									backgroundColor: "#23374C",
									borderRadius: 3,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									marginBottom: "2vh",
									transform:
										plan.Subscription.id !== "" && plan.Subscription.cancelled_at === 0
											? "scale(1.10)"
											: "none",
									transition: "transform 0.3s ease",
									boxShadow:
										plan.Subscription.id !== "" && plan.Subscription.cancelled_at === 0
											? "0 10px 30px rgba(0, 0, 0, 0.25)"
											: "0 4px 10px rgba(0, 0, 0, 0.1)",
									border:
										plan.Subscription.id !== "" && plan.Subscription.cancelled_at === 0
											? "4px solid #FFD700"
											: "none",
								}}
							>
								<Typography
									sx={{
										fontSize: 18,
										color: "white",
										textAlign: "center",
										width: "100%",
										m: 0,
										fontWeight: 600,
									}}
								>
									{plan.Subscription.id !== "" ? "Current" : "Choose"}
									<br />
									{plan.Subscription.status === "non_renewing" &&
										`(To be cancelled on ${new Date(plan.Subscription.cancelled_at * 1000).toDateString()})`}
								</Typography>
								<Card
									sx={{
										borderRadius: 3,
										width: "98%",
										backgroundColor: "#F3F5F1",
										flex: 1,
										mb: "8px",
									}}
								>
									<CardActionArea
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column",
										}}
										onClick={() => handleCardClick(plan)}
									>
										<CardContent
											sx={{
												width: "100%",
												backgroundColor: "#F3F5F1",
												textAlign: "center",
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}
										>
											<CardMedia
												sx={{
													marginTop: "15px",
													marginBottom: "15px",
													display: "flex",
													justifyContent: "center",
													width: "80%",
												}}
												component="img"
												image={BasePlan}
												alt="Base-plan"
											/>
										</CardContent>
										<CardContent sx={{ width: "100%", textAlign: "center" }}>
											<Typography variant="h4" component="div" fontWeight={450}>
												{plan.Plan.external_name}
											</Typography>
										</CardContent>
										<CardContent sx={{ textAlign: "center" }}>
											<CardContent
												sx={{
													display: "flex",
													justifyContent: "center",
													minHeight: "175px",
													padding: 0,
												}}
											>
												<div>
													{plan.Prices.map((price, idx) => (
														<Typography
															key={idx}
															variant="body1"
															padding="5px"
															margin="0"
															fontWeight={650}
														>
															{price.trial_period_unit === ""
																? " "
																: `A ${price.trial_period} ${price.trial_period_unit} free trial`}
														</Typography>
													))}
													{plan.Plan.description.split(",").map((item, idx) => (
														<Typography
															key={idx}
															variant="body2"
															sx={{ fontSize: 16, mt: 1, textAlign: "left" }}
														>
															<li>{item.trim()}</li>
														</Typography>
													))}
												</div>
											</CardContent>
											{plan.Prices.map((price, idx) => (
												<CardContent
													sx={{
														display: "flex",
														justifyContent: "center",
													}}
													key={idx}
												>
													<Typography sx={{ fontSize: "2rem", fontWeight: 700 }}>
														{price.currency_code === "EUR" ? "€" : price.currency_code}
														{price.price / 100} / {price.period_unit}
													</Typography>
													{idx < plan.Prices.length - 1 && (
														<Typography variant="body1" sx={{ fontSize: 20, mt: 2 }}>
															OR
														</Typography>
													)}
												</CardContent>
											))}
										</CardContent>
									</CardActionArea>
								</Card>
							</Box>
						))}
					</Box>
					<CancelSubscription
						userSubscription={activeSubscription}
						buttonText="Subscription"
						apiEndpoint="/api/cancel-subscription"
						httpMethod="POST"
					/>
				</div>
			) : (
				<CircularProgress />
			)}
		</div>
	);
};

export default SubscriptionPlans;
