import React from "react";
import "../Datapage/Datapage.css";
import BarChart from "./BarChart";

const FlanksChart = ({ firstClubData }) => {
	const flankData = {
		left: [
			firstClubData?.LeftFlankAttacks,
			firstClubData?.CrossesFromLeftFlank,
			firstClubData?.CrossesFromLeftFlankSuccessful,
		],
		right: [
			firstClubData?.RightFlankAttacks,
			firstClubData?.CrossesFromRightFlank,
			firstClubData?.CrossesFromRightFlankSuccessful,
		],
	};

	const labelsFlank = ["Flank Attacks", "Crosses", "Successful crosses"];

	const flankxGData = {
		left: [firstClubData?.LeftFlankXg],
		right: [firstClubData?.RightFlankXg],
	};
	const labelsxG = ["Flank xG"];

	return (
		<>
			<h2>{firstClubData?.ClubGroup}</h2>

			<div
				className="chart-section"
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
				}}
			>
				<div style={{ flex: "1 1 35%" }}>
					<h2>Flank</h2>
					<BarChart data={flankData} labels={labelsFlank} />
				</div>

				<div style={{ flex: "1 1 35%" }}>
					<h2>Flank xG</h2>
					<BarChart data={flankxGData} labels={labelsxG} />
				</div>
			</div>
		</>
	);
};

export default FlanksChart;
