import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	CircularProgress,
	styled,
	Typography,
} from "@mui/material";
import BasePlan from "../Logos/GoalunitLogo_NavyHorisontal.svg";
import CancelSubscription from "./CancelSubscription";

const Addons = () => {
	const [plans, setPlans] = useState([]);
	const [activeSubscription, setActiveSubscription] = useState([]);
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		const fetchSubscriptionplans = async () => {
			try {
				const token = await getAccessTokenSilently();
				const header = { Authorization: `Bearer ${token}` };
				const subscriptionplans = await fetch("/api/addons", {
					headers: header,
					credentials: "include",
				});

				if (!subscriptionplans.ok) {
					throw new Error(`HTTP error! Status: ${subscriptionplans.status}`);
				}

				const plan_response = await subscriptionplans.json();

				const sortedPlans = plan_response.sort((a, b) => {
					const minPriceA = Math.min(...a.Prices.map((priceObj) => priceObj.price));
					const minPriceB = Math.min(...b.Prices.map((priceObj) => priceObj.price));

					return minPriceA - minPriceB;
				});

				setPlans(sortedPlans);
				setActiveSubscription(sortedPlans.filter((plan) => plan.Subscription.id !== ""));
			} catch (error) {
				console.log("Something went wrong", error);
			}
		};
		fetchSubscriptionplans();
	}, []);

	const handleCardClick = async (plan) => {
		if (plan.Subscription.id === "" || plan.Subscription.cancelled_at > 0) {
			try {
				const token = await getAccessTokenSilently();
				const header = { Authorization: `Bearer ${token}` };
				const body = JSON.stringify({ itemPriceId: plan.Prices[0].id });
				const response = await fetch("/api/upsertsubscription", {
					method: "POST",
					headers: header,
					credentials: "include",
					body: body,
				});
				const hostedpage_response = await response.json();
				localStorage.setItem("shouldStartPolling", "true");
				window.location.href = hostedpage_response.url;
			} catch (error) {
				console.log("Something went wrong", error);
			}
		}
	};

	return (
		<div>
			{plans.length > 0 ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Typography variant="h2" sx={{ marginBottom: 4 }}>
						Available Addons
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 3,
							alignItems: "center",
							width: "100%",
						}}
					>
						{plans.map((plan, index) => (
							<Card
								key={index}
								sx={{
									width: "80%",
									backgroundColor: "#F3F5F1",
									borderRadius: 3,
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									boxShadow:
										plan.Subscription.id !== "" && plan.Subscription.cancelled_at === 0
											? "0 10px 30px rgba(0, 0, 0, 0.25)"
											: "0 4px 10px rgba(0, 0, 0, 0.1)",
									border:
										plan.Subscription.id !== "" && plan.Subscription.cancelled_at === 0
											? "4px solid #FFD700"
											: "none",
									transition: "transform 0.3s ease",
									"&:hover": {
										transform: "scale(1.02)",
									},
									position: "relative",
								}}
							>
								{plan.Subscription.id !== "" && (
									<Box
										sx={{
											position: "absolute",
											top: 8,
											right: 8,
											backgroundColor: "#FFD700",
											color: "#23374C",
											padding: "4px 8px",
											borderRadius: "4px",
											fontWeight: "bold",
											fontSize: "1rem",
										}}
									>
										Active
									</Box>
								)}
								<CardActionArea
									sx={{
										display: "flex",
										flexDirection: "row",
										width: "100%",
									}}
									onClick={() => handleCardClick(plan)}
								>
									<CardMedia
										sx={{
											width: 200,
											height: 200,
											objectFit: "contain",
											margin: 2,
										}}
										component="img"
										image={BasePlan}
										alt="Base-plan"
									/>
									<CardContent
										sx={{
											flex: 1,
											color: "#23374C",
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography
											variant="h5"
											component="div"
											fontWeight={600}
											sx={{ marginBottom: 1 }}
										>
											{plan.Plan.external_name}
										</Typography>
										<Typography variant="body2" sx={{ marginBottom: 2 }}>
											{plan.Plan.description}
										</Typography>
										{plan.Prices.map((price, idx) => (
											<Typography key={idx} sx={{ fontSize: "1.25rem", fontWeight: 700 }}>
												{price.currency_code === "EUR" ? "€" : price.currency_code}
												{price.price / 100} / {price.period_unit}
											</Typography>
										))}
									</CardContent>
									{plan.Subscription.id !== "" && (
										<Box sx={{ marginRight: 2 }}>
											<CancelSubscription
												userSubscription={activeSubscription}
												buttonText="Add-on"
												apiEndpoint="/api/addons"
												httpMethod="PATCH"
											/>
										</Box>
									)}
								</CardActionArea>
							</Card>
						))}
					</Box>
				</div>
			) : (
				<CircularProgress />
			)}
		</div>
	);
};

export default Addons;
