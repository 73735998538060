import React, { useMemo } from "react";
import "../Datapage/Datapage.css";
import CustomTable from "../Components/Table";

const PostReportOverview = ({ data, impersonationInfo }) => {
	const { teamData, opponentData } = useMemo(() => {
		const teamData =
			data[0]?.ClubGroup === impersonationInfo?.ClubName ? data[0] : data[1] || {};
		const opponentData =
			data[0]?.ClubGroup === impersonationInfo?.ClubName ? data[1] : data[0] || {};

		return { teamData, opponentData };
	}, [data, impersonationInfo]);
	const tableData = useMemo(() => {
		return [
			{
				team: impersonationInfo?.ClubName,
				goals: parseFloat(teamData.Goals),
				xg: parseFloat(teamData.XG).toFixed(2),
				nonPenaltyxg: parseFloat(teamData.NonPenaltyXG).toFixed(2),
				redCards: parseFloat(teamData.Avg_RedCards),
				possession: parseFloat(teamData.PossessionPercentage).toFixed(2),
			},
			{
				team: opponentData.ClubGroup,
				goals: parseFloat(opponentData.Goals),
				xg: parseFloat(opponentData.XG).toFixed(2),
				nonPenaltyxg: parseFloat(opponentData.NonPenaltyXG).toFixed(2),
				redCards: parseFloat(opponentData.Avg_RedCards),
				possession: parseFloat(opponentData.PossessionPercentage).toFixed(2),
			},
		];
	}, [teamData, opponentData, impersonationInfo?.ClubName]);

	const tableColumns = useMemo(() => {
		return [
			{ field: "team", headerName: "Team" },
			{ field: "goals", headerName: "Goals" },
			{ field: "xg", headerName: "xG" },
			{ field: "nonPenaltyxg", headerName: "Non-penalty xG" },
			{ field: "redCards", headerName: "Red Cards" },
			{ field: "possession", headerName: "Possession" },
		];
	}, []);

	return (
		<div
			className="chart-section"
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
			}}
		>
			<div style={{ flex: "1 1 80%" }}>
				<CustomTable tableData={tableData} tableColumns={tableColumns} />
			</div>
		</div>
	);
};

export default PostReportOverview;
