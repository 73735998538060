import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { Chart, registerables } from "chart.js";
import "../Style/am_style.css";
import Box from "@mui/material/Box";
import { toJS } from "mobx";
import CustomTable from "../Components/Table";
import { color } from "../Style/theme";
import { seasonNameShortener } from "./utilFunctions";

Chart.register(...registerables);

const PlayTimeDistribution = (props) => {
	const [chartData, setChartData] = useState({ datasets: [] });
	const [dataLoaded, setDataLoaded] = useState(false);

	const chartRef = useRef(null);

	const backgroundColors = [
		color.mono.fourth,
		color.secondary.main,
		color.warning.main,
		color.primary.main,
	];

	const loadData = (jsonData) => {
		const data = toJS(jsonData);
		const maxMatchesInSeason = {};
		const rankedSeasonalData = [];

		const ageCategories = new Set();

		data.forEach((item) => {
			ageCategories.add(item.AgeCategory);
		});

		const ageCategoriesOrder = Array.from(ageCategories);

		ageCategoriesOrder.sort((a, b) => {
			const getLowestNumber = (str) => {
				const match = str.match(/\d+/);
				return match ? parseInt(match[0]) : 0;
			};

			return getLowestNumber(a) - getLowestNumber(b);
		});

		data.forEach((item) => {
			const { SeasonName, MatchNo } = item;
			if (!maxMatchesInSeason[SeasonName] || MatchNo > maxMatchesInSeason[SeasonName]) {
				maxMatchesInSeason[SeasonName] = MatchNo;
			}
		});

		data.forEach((item) => {
			const { SeasonName, MatchNo } = item;
			if (MatchNo === maxMatchesInSeason[SeasonName]) {
				rankedSeasonalData.push(item);
			}
		});

		const labels = Array.from(
			new Set(rankedSeasonalData.map((item) => item.SeasonName))
		).sort();

		const datasets = ageCategoriesOrder.map((ageCategory, index) => {
			return {
				label: ageCategory,
				data: labels.map((season) => {
					const item = rankedSeasonalData.find(
						(d) => d.SeasonName === season && d.AgeCategory === ageCategory
					);
					return item ? item.PartOfPlayingTime : 0;
				}),
				fill: true,
				borderColor: color.primary.contrast,
				backgroundColor: backgroundColors[index],
				borderWidth: 0,
			};
		});

		setChartData({
			labels: labels,
			datasets: datasets,
		});

		setDataLoaded(true);
	};

	useEffect(() => {
		loadData(props.data);
	}, [props.data]);

	const tableColumns = [
		{ headerName: "Season", field: "SeasonName", customStyle: { textAlign: "center" } },
		{
			headerName: "Age category",
			field: "AgeCategory",
			customStyle: { textAlign: "center" },
		},
		{
			headerName: "Part of playing time",
			field: "PartOfPlayingTime",
			customStyle: { textAlign: "center" },
		},
	];

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: "nearest",
			axis: "x",
			intersect: false,
		},
		scales: {
			x: {
				title: {
					display: true,
					text: "Season",
				},
				ticks: {
					callback: function (value) {
						const scale = this;
						const season = scale.getLabelForValue(value);
						return seasonNameShortener(season);
					},
				},
			},
			y: {
				title: {
					display: true,
					text: "Percent (%)",
				},
				min: 0,
				max: 100,
				stacked: true,
			},
		},
		plugins: {
			legend: {
				position: "top",
				labels: {
					padding: 20,
					font: {
						size: 16, // Adjust this value to change the font size
					},
				},
			},
			tooltip: {
				itemSort: (a, b) => b.datasetIndex - a.datasetIndex, // reverse order of items in tooltip
				callbacks: {
					labelColor: (context) => {
						const dataset = context.dataset;
						return {
							borderColor: dataset.backgroundColor,
							backgroundColor: dataset.backgroundColor,
						};
					},
					label: function (context) {
						const label = context.dataset.label || "";
						const value = context.raw;
						const formattedValue = `${Math.round(value)}%`;

						return `${label}: ${formattedValue}`;
					},
				},
			},
			crosshair: {
				color: color.primary.contrast,
			},
		},
	};

	/*
	Important note! Chart.js uses its parent container to update the canvas render and display sizes. 
	This method requires the container to be relatively positioned and 
	dedicated to the chart canvas only. Responsiveness can then be achieved by setting 
	relative values for the container size
	*/
	return (
		dataLoaded && (
			<Box
				sx={{
					position: "relative",
					width: "100%",
					height: "100%",
					maxHeight: { sx: "80vh", lg: "50vh" },
				}}
			>
				{props.data.length > 4 ? (
					<Line
						data={chartData}
						options={chartOptions}
						width={600}
						height={450}
						ref={chartRef}
					/>
				) : (
					<CustomTable tableColumns={tableColumns} tableData={props.data} />
				)}
			</Box>
		)
	);
};

export default PlayTimeDistribution;
