import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import isAdministrator from "../../Authorization/isAdministrator";
import theme from "../../Style/theme";
import { routes } from "../../Util/routes";
import Impersonation from "../Impersonation";
import NoteWindow from "../NoteWindow";
import GoalUnitLogo from "./GoalUnitLogo";
import MobileMenu from "./MobileMenu";

const NavBar = styled("nav")(({ theme }) => ({
	position: "sticky",
	top: "0px",
	display: "flex",
	gap: theme.spacing(3),
	justifyContent: "flex-end",
	backgroundColor: theme.palette.background.main,
	borderBottom: "1px solid rgba(0, 0, 0, 0.07)",
	fontSize: "30px",
	alignItems: "center",
	padding: "20px",
	zIndex: 1000 /* Ensure it's on top of other content */,
	textOverflow: "ellipsis",
	height: "80px",
	boxSizing: "border-box",

	[theme.breakpoints.down("sm")]: {
		height: "60px",
		padding: "10px",
		gap: theme.spacing(2),
	},
}));

const menuLinkStyles = {
	display: "inline-block",
	textDecoration: "none",
	fontFamily: theme.typography.fontFamily,
	fontSize: "25px",
	fontWeight: 700,
	whiteSpace: "nowrap",
	padding: "10px 16px",
	color: theme.palette.primary.main,
	cursor: "pointer",

	"&:hover, &.active": {
		color: theme.palette.primary.light,
	},

	[theme.breakpoints.down("xxxl")]: {
		fontSize: "20px",
	},
};

const StyledScrollLink = styled(ScrollLink)(({ theme, hidefrom }) => ({
	...menuLinkStyles,
	[theme.breakpoints.down(hidefrom)]: {
		display: "none",
	},
}));

const StyledNavLink = styled(NavLink)(({ theme, hidefrom }) => ({
	...menuLinkStyles,
	[theme.breakpoints.down(hidefrom)]: {
		display: "none",
	},
}));

function Navbar() {
	const { user, logout, isAuthenticated } = useAuth0();
	const location = useLocation();
	const scrolldown_nav_path = location.pathname.split("/")[1];

	const [offset, setOffset] = useState(getOffset());

	useEffect(() => {
		const handleResize = () => setOffset(getOffset());

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	function getOffset() {
		const width = window.innerWidth;
		if (width > 2048) return -80;
		if (width > 768) return -60;
		return -60;
	}

	const menuItems = useMemo(() => {
		const handleLogout = () => {
			localStorage.removeItem("ClubOverviewStore");
			localStorage.removeItem("ImpersonationStore");
			localStorage.removeItem("ProfileStore");
			localStorage.removeItem("UserEntitlementStore");
			logout();
		};

		if (location.pathname === "/") {
			return [
				{
					to: routes.start.home,
					label: "Home",
					type: "scroll",
					offset: offset,
				},
				isAuthenticated
					? {
							to: routes.am.club.base,
							label: "Assistant Manager",
							type: "nav",
						}
					: {
							to: routes.start.am,
							label: "Assistant Manager",
							type: "scroll",
							offset: -80,
						},
				{
					to: routes.start.services,
					label: "Services",
					type: "scroll",
					offset: offset,
				},
				{
					to: routes.start.about,
					label: "About",
					type: "scroll",
					offset: offset,
				},
				{
					to: routes.start.contact,
					label: "Contact",
					type: "scroll",
					offset: offset,
				},
			];
		} else if (isAuthenticated && scrolldown_nav_path === "assistant-manager") {
			return [
				{
					to: routes.am.club.base,
					label: "Club",
					type: "nav",
				},

				{
					to: routes.am.profile,
					label: "Profile",
					type: "nav",
				},
				{
					to: routes.am.logOut,
					label: "Logout",
					type: "nav",
					onClick: handleLogout,
				},
			];
		} else {
			return [];
		}
	}, [location.pathname, isAuthenticated, offset, scrolldown_nav_path, logout]);

	const isStartPage = useMemo(() => {
		return location.pathname === "/";
	}, [location.pathname]);

	return (
		<NavBar>
			<NavLink to="/" onClick={() => window.scrollTo(0, 0)}>
				<GoalUnitLogo />
			</NavLink>

			{isAuthenticated && scrolldown_nav_path === "assistant-manager" && (
				<Impersonation />
			)}

			{menuItems.map((item, i) =>
				item.type === "scroll" ? (
					<StyledScrollLink
						key={i}
						to={item.to}
						smooth={true}
						duration={500}
						hashSpy={true}
						offset={item.offset}
						hidefrom={isStartPage ? "lg" : "xl"}
					>
						{item.label}
					</StyledScrollLink>
				) : (
					<StyledNavLink
						key={i}
						to={item.to}
						onClick={item.onClick}
						hidefrom={isStartPage ? "lg" : "xl"}
					>
						{item.label}
					</StyledNavLink>
				)
			)}
			{isAdministrator(user?.email) && scrolldown_nav_path === "assistant-manager" && (
				<NoteWindow />
			)}

			<MobileMenu menuItems={menuItems} hideFrom={isStartPage ? "lg" : "xl"} />
		</NavBar>
	);
}

export default Navbar;
