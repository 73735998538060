import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useOutletContext, useSearchParams } from "react-router-dom";
import ExportPDF from "../Components/ExportPDF";
import { auth0Service } from "../Util/Auth0Client";
import useDebounce from "../Util/useDebounce";
import ChanceChart from "./ChanceChart";
import DangerousPlayers from "./DangerousPlayers";
import DefenceChart from "./DefenceChart";
import FlanksChart from "./FlanksChart";
import PassingsChart from "./PassingsChart";

const PreReportContainer = () => {
	const chartRefs = useRef([]);
	const [firstClubData, setFirstClubData] = useState([]);
	const [secondClubData, setSecondClubData] = useState([]);

	const [isLoading, setIsLoading] = useState();
	const [error, setError] = useState(null);

	const [dangerousPlayers, setDangerousPlayers] = useState([]);
	const [matchData, setMatchData] = useState([]);
	const [leagueAvg, setLeagueAvg] = useState({});
	const [defenceTableData, setDefenceTableData] = useState([]);

	const { impersonationInfo } = useOutletContext();

	const [searchParams, setSearchParams] = useSearchParams();

	// debounce so we dont fetch directly
	const debouncedMatchId = useDebounce(searchParams.get("matchId"), 400);

	useEffect(() => {
		const fetchData = async (matchId) => {
			setIsLoading(true);
			setError(null);

			try {
				const token = await auth0Service.getToken();
				const header = { Authorization: `Bearer ${token}` };
				const response = await fetch(`/api/matchreport/pre?matchId=${matchId}`, {
					headers: header,
					credentials: "include",
				});

				if (!response.ok) {
					throw new Error("Failed to fetch pre match report data");
				}

				const preMatchReportData = await response.json();

				setMatchData(preMatchReportData.MR[0] || []);
				setDangerousPlayers(preMatchReportData.DP[0] || []);
				setLeagueAvg(preMatchReportData.LM || {});
			} catch (error) {
				setError(error.message);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData(debouncedMatchId);
	}, [debouncedMatchId]);

	useEffect(() => {
		const opponentData = matchData.find((md) => md?.ClubGroup === "opponentclubs") || {};
		const teamData = matchData.find((md) => md?.ClubGroup !== "opponentclubs") || {};
		setFirstClubData(teamData);
		setSecondClubData(opponentData);

		const defenceTableData = [
			{
				team: teamData?.ClubGroup,
				fouls: parseFloat(teamData.Avg_Fouls)?.toFixed(2),
				yellowCards: parseFloat(teamData.Avg_YellowCards)?.toFixed(2),
				redCards: parseFloat(teamData.Avg_RedCards)?.toFixed(2),
			},
			{
				team: "Opponents",
				fouls: parseFloat(opponentData.Avg_Fouls)?.toFixed(2),
				yellowCards: parseFloat(opponentData.Avg_YellowCards)?.toFixed(2),
				redCards: parseFloat(opponentData.Avg_RedCards)?.toFixed(2),
			},
			{
				team: "League average",
				fouls: leagueAvg.AvgFouls?.toFixed(2),
				yellowCards: leagueAvg.AvgYellowCards?.toFixed(2),
				redCards: leagueAvg.AvgRedCards?.toFixed(2),
			},
		];

		setDefenceTableData(defenceTableData);
	}, [matchData]);

	return (
		<>
			<div className="custom-separator"></div>
			{error && <div style={{ textAlign: "center" }}>Error: {error}</div>}

			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					flex: 1,
					marginTop: "10px",
					marginRight: "50px",
				}}
			>
				<ExportPDF
					titles={[
						"Passing",
						"Defence",
						"Chance creation",
						"Dangerous players",
						"Flanks",
					]}
					coverTitle={"Pre-Match Report ".concat(firstClubData?.ClubGroup || "")}
					refs={chartRefs}
				/>
			</div>
			<div className="chart-background">
				<h2 className="subtitle">Passing</h2>
				<div className="chart-container">
					{isLoading ? (
						<CircularProgress />
					) : (
						<div ref={(el) => (chartRefs.current[0] = el)}>
							<PassingsChart
								firstClubData={firstClubData}
								secondClubData={{ ...secondClubData, ClubGroup: "Opponents" }}
								impersonationInfo={impersonationInfo}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="chart-background">
				<h2 className="subtitle"> Defence </h2>
				<div className="chart-container">
					{isLoading ? (
						<CircularProgress />
					) : (
						<div ref={(el) => (chartRefs.current[1] = el)}>
							<DefenceChart
								firstClubData={firstClubData}
								secondClubData={{ ...secondClubData, ClubGroup: "Opponents" }}
								leagueAvg={leagueAvg}
								tableData={defenceTableData}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="chart-background">
				<h2 className="subtitle"> Chance creation </h2>
				<div className="chart-container">
					{isLoading ? (
						<CircularProgress />
					) : (
						<div ref={(el) => (chartRefs.current[2] = el)}>
							<ChanceChart
								firstClubData={firstClubData}
								secondClubData={{ ...secondClubData, ClubGroup: "Opponents" }}
								leagueAvg={leagueAvg}
								impersonationInfo={impersonationInfo}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="chart-background">
				<h2 className="subtitle"> Most dangerous players </h2>
				<div className="chart-container">
					{isLoading ? (
						<CircularProgress />
					) : (
						<div ref={(el) => (chartRefs.current[3] = el)}>
							<DangerousPlayers data={dangerousPlayers} />
						</div>
					)}
				</div>
			</div>
			<div className="chart-background">
				<h2 className="subtitle"> Flanks </h2>
				<div className="chart-container">
					{isLoading ? (
						<CircularProgress />
					) : (
						<div ref={(el) => (chartRefs.current[4] = el)}>
							<FlanksChart firstClubData={firstClubData} />
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default PreReportContainer;
