import React, { useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import EditIcon from "@mui/icons-material/Edit";
import FlagIcon from "@mui/icons-material/FlagRounded";
import { Button, Dialog, DialogActions, DialogContent, IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import isAdministrator from "../Authorization/isAdministrator";
import CustomTable from "../Components/Table";
import "../Style/am_style.css";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import profile from "../Logos/empty_profile.png";
import ReportOrEditPlayerModal from "./ReportOrEditPlayerModal";
import { squad } from "./squad.js";

const closeBtnStyle = {
	position: "absolute",
	top: 8,
	right: 8,
};

const SquadContainer = observer(({ data, onEditSubmit }) => {
	const { user } = useAuth0();

	const [selectedPlayer, setSelectedPlayer] = useState(null);
	const [selectedEditPlayer, setSelectedEditPlayer] = useState(null);
	const [selectedReportPlayer, setSelectedReportPlayer] = useState(null);
	const [updateConfirmation, setUpdateConfirmation] = useState();

	const players = data.map((player) => ({
		ClubId: player.ClubId,
		ClubName: player.ClubName,
		PlayerId: player.PlayerId,
		Name: `${player.FirstName} ${player.LastName}`,
		FirstName: player.FirstName,
		LastName: player.LastName,
		DateOfBirth: player.DateOfBirth ? player.DateOfBirth : null,
		Foot: player.Foot,
		Height: player.Height,
		Weight: player.Weight,
		PositionId: player.PositionId,
		PositionName: player.PositionName,
		TransferKPI: player.TransferKPI,
		ContractExpiration: player.ContractExpiration ? player.ContractExpiration : null,
		BirthPlaceAreaId: player.BirthplaceAreaId,
		BirthPlaceArea: player.BirthplaceArea,
		PassPortAreaId: player.PassportAreaId,
		PassPortArea: player.PassportArea,
	}));

	const tableColumns = useMemo(() => {
		const columns = [
			{
				field: "Name",
				headerName: "Name",
				customContent: (field, row) => (
					<Button sx={{ whiteSpace: "nowrap" }} onClick={() => setSelectedPlayer(row)}>
						{field}
					</Button>
				),
			},
			{ field: "PositionName", headerName: "Position" },
			{ field: "Foot", headerName: "Foot" },
			{
				field: "DateOfBirth",
				headerName: "Date of Birth",
				customContent: (field, row) => dayjs(field).format("YYYY-MM-DD"),
			},
			{ field: "TransferKPI", headerName: "Transfer Kpi" },
			{ field: "BirthPlaceArea", headerName: "Birthplace" },
		];
		if (isAdministrator(user?.email)) {
			columns.push({
				field: "",
				headerName: "Edit",

				customContent: (field, row) => (
					<IconButton onClick={() => handleEditClick(row)}>
						<EditIcon sx={{ color: "primary.main" }} />
					</IconButton>
				),
				disableSort: true,
			});
		} else {
			columns.push({
				field: "",
				headerName: "Report error",
				headerInfo: "Press the flag icon to report an error in the data",
				customContent: (field, row) => (
					<IconButton onClick={() => handleReportClick(row)}>
						<FlagIcon sx={{ color: "primary.main" }} />
					</IconButton>
				),
				disableSort: true,
			});
		}
		return columns;
	}, [user.email]);

	const handleReportClick = (player) => {
		setSelectedReportPlayer(player);
	};

	const handleEditClick = (player) => {
		setSelectedEditPlayer(player);
	};

	const handleEditSubmit = async (
		positionId,
		birthplaceAreaId,
		birthDate,
		contractExpiration,
		foot,
		height,
		weight,
		passportAreaId,
		lastname,
		firstname
	) => {
		try {
			setUpdateConfirmation("");

			if (selectedEditPlayer) {
				await squad.updatePlayer(
					selectedEditPlayer.PlayerId,
					selectedEditPlayer.ClubId,
					firstname,
					lastname,
					birthDate.format("YYYY-MM-DDTHH:mm:ssZ"),
					birthplaceAreaId,
					passportAreaId,
					foot,
					height,
					weight,
					contractExpiration.format("YYYY-MM-DDTHH:mm:ssZ"),
					positionId
				);
				setUpdateConfirmation("Successfully updated player!");
			}
		} catch (e) {
			setUpdateConfirmation(
				"Could not update player. Please try again or contact support."
			);
		} finally {
			setSelectedEditPlayer(null);
			onEditSubmit();
		}
	};

	const handleReportSubmit = async (
		positionId,
		birthplaceAreaId,
		birthDate,
		contractExpiration,
		foot,
		height,
		weight,
		passportAreaId,
		lastname,
		firstname
	) => {
		try {
			setUpdateConfirmation("");

			if (selectedReportPlayer) {
				await squad.reportPlayerError(
					selectedReportPlayer.PlayerId,
					selectedReportPlayer.ClubId,
					firstname,
					lastname,
					birthDate.format("YYYY-MM-DDTHH:mm:ssZ"),
					birthplaceAreaId,
					passportAreaId,
					foot,
					height,
					weight,
					contractExpiration.format("YYYY-MM-DDTHH:mm:ssZ"),
					positionId
				);
				setUpdateConfirmation(
					"Your report has been notified to the team. Thank you for your feedback!"
				);
			}
		} catch (e) {
			setUpdateConfirmation(
				"Could not send report. Please try again or contact support."
			);
		} finally {
			setSelectedReportPlayer(null);
			onEditSubmit();
		}
	};

	return (
		<>
			<CustomTable tableData={players} tableColumns={tableColumns} />
			{selectedEditPlayer && (
				<ReportOrEditPlayerModal
					open={!!selectedEditPlayer}
					player={selectedEditPlayer}
					onSave={handleEditSubmit}
					onClose={() => setSelectedEditPlayer(null)}
					mode="Edit"
				/>
			)}
			{selectedReportPlayer && (
				<ReportOrEditPlayerModal
					open={!!selectedReportPlayer}
					player={selectedReportPlayer}
					onSave={handleReportSubmit}
					onClose={() => setSelectedReportPlayer(null)}
					mode="Report"
				/>
			)}
			<Dialog open={!!updateConfirmation}>
				<DialogContent>{updateConfirmation}</DialogContent>
				<DialogActions>
					<Button autoFocus variant="contained" onClick={() => setUpdateConfirmation()}>
						Ok
					</Button>
				</DialogActions>
			</Dialog>

			{selectedPlayer && (
				<div className="selected-player">
					<div className="player-details">
						<IconButton
							sx={closeBtnStyle}
							aria-label="close"
							onClick={() => setSelectedPlayer(null)}
						>
							<CloseIcon />
						</IconButton>
						<h1>
							{selectedPlayer.FirstName} {selectedPlayer.LastName}
						</h1>
						<div className="info-pieces">
							<div className="info-piece">
								<img src={profile} className="profile-image" alt="profile" />
							</div>
							<div className="info-piece">
								<p>Position: {selectedPlayer.PositionName}</p>
								<p>Foot: {selectedPlayer.Foot}</p>
								<p>
									Date of Birth: {dayjs(selectedPlayer.DateOfBirth).format("YYYY-MM-DD")}
								</p>
								<p>Birthplace: {selectedPlayer.BirthPlaceArea}</p>
								<p>Passport: {selectedPlayer.PassPortArea}</p>
								<p>Height: {selectedPlayer.Height}</p>
								<p>Weight: {selectedPlayer.Weight}</p>
								<p>
									Contract Until:{" "}
									{dayjs(selectedPlayer.ContractExpiration).format("YYYY-MM-DD")}{" "}
								</p>
								<p>TransferKPI: {selectedPlayer.TransferKPI} </p>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
});

export default SquadContainer;
