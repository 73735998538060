/**
 * This plugin displays a crosshair over the datapoint closest to the mouse pointer
 */
const crosshairPlugin = {
	id: "crosshair",
	defaults: {
		width: 0.5,
		color: "#666",
		dash: [3, 3],
	},
	afterInit: (chart, args, opts) => {
		chart.crosshair = {
			x: 0,
			y: 0,
		};
	},
	afterEvent: (chart, args) => {
		const { inChartArea } = args;
		const { x, y } = args.event;

		const chartType = chart.config.type;
		const displayCrosshair = chartType === "line" || chartType === "bar";

		chart.crosshair = { x, y, draw: inChartArea && displayCrosshair };
		chart.draw();
	},
	afterDatasetsDraw: (chart, args, opts) => {
		const { ctx } = chart;
		const { top, bottom, left, right } = chart.chartArea;
		const { x: mouseX, y: mouseY, draw } = chart.crosshair;

		if (!draw) return;

		let x = 0;
		let y = 0;

		if (!!chart?.tooltip?.dataPoints?.length) {
			x = chart.tooltip.dataPoints[0].element.x;
			const yPositions = chart.tooltip.dataPoints.map((point) => point.element.y);

			y = yPositions.reduce((prev, curr) => {
				return Math.abs(curr - mouseY) < Math.abs(prev - mouseY) ? curr : prev;
			});
		} else {
			x = mouseX;
			y = mouseY;
		}

		ctx.save();

		ctx.beginPath();
		ctx.lineWidth = opts.width;
		ctx.strokeStyle = opts.color;
		ctx.setLineDash(opts.dash);
		ctx.moveTo(x, bottom);
		ctx.lineTo(x, top);
		ctx.moveTo(left, y);
		ctx.lineTo(right, y);
		ctx.stroke();

		ctx.restore();
	},
};

export default crosshairPlugin;
