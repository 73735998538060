import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { auth0Service } from "../Util/Auth0Client";

export default class ProfileStore {
	currentTabIndex = 0;
	userEmail = null;
	isUserEmailValid = false;
	sendEmailStatus = "";
	clearStatusTimeOut = null;

	async init() {
		const user = await auth0Service.getUser();
		runInAction(() => {
			this.setUserEmail(user.email);
		});
	}

	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: "ProfileStore",
			properties: ["currentTabIndex", "userEmail"],
			storage: window.localStorage,
		});
	}

	sendEmail = async () => {
		if (!this.validateEmail(this.userEmail)) {
			runInAction(() => {
				this.setIsUserEmailValid(true);
			});
		} else {
			await this.AskForPasswordResetEmail(this.userEmail);
		}
	};

	AskForPasswordResetEmail = async (email) => {
		const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
		const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
		try {
			const response = await fetch(
				`https://${AUTH_DOMAIN}/dbconnections/change_password`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						client_id: `${AUTH_CLIENT_ID}`,
						email: `${email}`,
						connection: "Username-Password-Authentication",
					}),
				}
			);
			if (response.ok) {
				runInAction(() => {
					this.setSendEmailStatus("Password reset email sent successfully.");
				});
			}
		} catch (error) {
			runInAction(() => {
				this.setSendEmailStatus(
					"Password reset email can't be sent at this moment. Please try again later."
				);
			});
		}
	};

	setUserEmail(email) {
		this.userEmail = email;
	}

	setCurrentTabIndex(index) {
		this.currentTabIndex = index;
	}

	setIsUserEmailValid(validity) {
		this.isUserEmailValid = validity;
	}

	setSendEmailStatus(status) {
		this.sendEmailStatus = status;

		if (this.clearStatusTimeOut) {
			clearTimeout(this.clearStatusTimeOut);
		}

		this.clearStatusTimeout = setTimeout(() => {
			runInAction(() => {
				this.sendEmailStatus = "";
				this.clearStatusTimeout = null;
			});
		}, 7000);
	}

	handleUserEmailChange = (event) => {
		runInAction(() => {
			this.setUserEmail(event.target.value);
		});
	};

	handleTabChange = (event, index) => {
		runInAction(() => {
			this.setCurrentTabIndex(index);
		});
	};

	validateEmail = (email) => {
		const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return re.test(String(email).toLowerCase());
	};
}

export const profileStore = new ProfileStore();
