import React from "react";
import { Bar } from "react-chartjs-2";
import { color } from "../Style/theme";

const BarChart = ({ data, labels }) => {
	const chartData = {
		labels: labels,
		datasets: [
			{
				label: "Left",
				data: data.left,
				backgroundColor: color.primary.main,
				borderColor: color.primary.main,
				fill: false,
				borderWidth: 0,
			},
			{
				label: "Right",
				data: data.right,
				backgroundColor: color.secondary.main,
				borderColor: color.secondary.main,
				fill: false,
				borderWidth: 0,
			},
		],
	};

	const options = {
		scale: {
			ticks: {
				stepSize: 1,
				suggestedMin: 0,
				suggestedMax: 10,
			},
		},
		plugins: {
			watermark: {
				xAlign: "right",
				yAlign: "top",
			},
		},
	};

	return <Bar data={chartData} options={options} width={600} height={500} />;
};

export default BarChart;
