import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Bubble } from "react-chartjs-2";
import "chart.js/auto";
import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	styled,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { Chart, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import datalabels from "chartjs-plugin-datalabels";
import dayjs from "dayjs";
import ColorGradient from "../Components/ColorGradient";
import CustomSlider from "../Components/CustomSlider";
import { impersonationStore } from "../State/ImpersonationStore";
import { season } from "../Util/season";
import { getColorForValue } from "./utilFunctions";
import "./Datapage.css";

Chart.register(...registerables, annotationPlugin);

const wrapperBoxStyle = {
	display: "flex",
	flexDirection: "column",
	gap: 2,
};

const sliderBoxStyle = {
	display: "flex",
	flexDirection: {
		xs: "column",
		md: "row",
	},
	justifyContent: "center",
	alignItems: "start",
	gap: {
		xs: 0,
		md: 2,
	},
};

const CustomSelect = styled(Select)(({ theme }) => ({
	width: "250px",
	margin: 0,
	"& .MuiInputBase-input": {
		textAlign: "center",

		[theme.breakpoints.down("md")]: {
			padding: theme.spacing(1),
		},
	},
	"& .MuiSelect-select": {
		display: "flex",
		alignItems: "center", // Vertically center the selected value
		justifyContent: "center", // Horizontally center the selected value
	},
}));

const SeasonDropDown = observer(() => {
	const [value, setValue] = useState("");
	const [seasonList, setSeasonList] = useState([]);

	useEffect(() => {
		season.fetchData().then(([list, selected]) => {
			setSeasonList(list);
			setValue(selected?.SeasonId);
		});
	}, [impersonationStore.selectedOption]);

	const handleChange = (e) => {
		season.handleChange(e);
		setValue(e.target.value);
	};

	return (
		<FormControl sx={{ margin: "0 auto" }}>
			<InputLabel id="season-select-label" sx={{ m: 0 }}>
				Season
			</InputLabel>
			<CustomSelect
				labelId="season-select-label"
				value={value}
				onChange={handleChange}
				label="Season"
			>
				{seasonList.map((season) => (
					<MenuItem key={season.SeasonId} value={season.SeasonId} sx={{ margin: 0 }}>
						{season.SeasonName}
					</MenuItem>
				))}
			</CustomSelect>
		</FormControl>
	);
});

const TransferKPI = (props) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const [chartData, setChartData] = useState({ datasets: [] });
	const [sliderValue, setSliderValue] = useState(null);
	const [max, setMaxSlidervalue] = useState(null);
	const [originalData, setOriginalData] = useState([]);
	const [customMarks, setMarks] = useState([]);
	const [isOnlyOneMatch, setIsOnlyOneMatch] = useState(false);
	const [lastSeasonMatch, setLastSeasonMatch] = useState(null);

	const chartRef = useRef(null);

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		layout: {
			padding: {
				right: isSmallScreen ? 20 : 60,
				top: isSmallScreen ? 20 : 60,
			},
		},
		datasets: {
			bubble: {
				clip: false,
			},
		},
		scales: {
			y: {
				type: "linear",
				min: 0,
				max: 100,
				ticks: {
					stepSize: 10,
				},
				title: {
					display: true,
					text: "Play time (%)",
				},
				beginAtZero: false,
			},
			x: {
				type: "linear",
				min: 16,
				max: 36, // The value shown on the chart should be "36+""
				ticks: {
					stepSize: 2,
					callback: function (value, index, values) {
						if (value === 36) {
							return "36+";
						} else if (value === 16) {
							return "16-";
						} else {
							return value;
						}
					},
				},
				title: {
					display: true,
					text: "Age",
				},
			},
		},
		plugins: {
			datalabels: {
				formatter: function (value) {
					return isSmallScreen ? "" : `${value.firstName[0]}. ${value.lastName}`;
				},
				align: "end",
				anchor: "end",
				offset: 2,
				padding: 0,
				clamp: true,
				clip: false,
				font: {
					size: 12,
					family: "Montserrat, sans-serif",
					fontWeight: 400,
				},

				color: "#333",
			},
			legend: {
				display: false,
			},
			annotation: {
				annotations: {
					line1: {
						type: "line",
						yMin: 0,
						yMax: 100,
						xMin: 25,
						xMax: 25,
						borderColor: "grey",
						borderWidth: 1,
						borderDash: [5, 5],
					},
					line2: {
						type: "line",
						yMin: 50,
						yMax: 50,
						xMin: 0,
						xMax: 36,
						borderColor: "grey",
						borderWidth: 1,
						borderDash: [5, 5],
					},
				},
			},
			tooltip: {
				callbacks: {
					title: function (context) {
						return context[0].raw.fullName;
					},
					label: function (context) {
						return [
							`KPI: ${context.raw.transferKPI}`,
							`Age: ${context.raw.originalAge}`,
							`Position: ${context.raw.positionName}`,
							`Play time: ${context.raw.y}%`,
							`Contract expiration: ${context.raw.contractExpiration}`,
						];
					},
				},
			},
			watermark: {
				xAlign: "right",
				yAlign: "top",
			},
		},
	};

	const loadData = (jsonData, isSmallScreen) => {
		try {
			const data = jsonData.map((player) => ({
				x: player.Age > 36 ? 36 : player.Age < 16 ? 16 : player.Age,
				y: player.PartOfPlayingTime > 100 ? 100 : player.PartOfPlayingTime,
				r: isSmallScreen ? 5 : (player.TransferKPI > 3 ? 3 : player.TransferKPI) * 5 + 5,
				originalAge: player.Age,
				positionName: player.PositionName,
				firstName: player.FirstName,
				lastName: player.LastName,
				fullName: player.PlayerName,
				matchesPlayedByTeam: player.MatchesPlayedByTeam,
				backgroundColor: getColorForValue(player.TransferKPI),
				contractExpiration: player.ContractExpiration
					? dayjs(player.ContractExpiration).format("YYYY-MM-DD")
					: "-",
				transferKPI: player.TransferKPI.toFixed(2),
			}));

			const matchesPlayed = Math.max(...data.map((d) => d.matchesPlayedByTeam));
			const sortedUniqueMatchesPlayed = [
				...new Set(data.map((item) => item.matchesPlayedByTeam)),
			].sort((a, b) => a - b);
			setSliderValue(matchesPlayed);
			setMaxSlidervalue(matchesPlayed);
			setOriginalData(data);
			filterData(matchesPlayed, data);

			const marks = () => {
				if (matchesPlayed !== sortedUniqueMatchesPlayed.length) {
					setIsOnlyOneMatch(true);
					setLastSeasonMatch(matchesPlayed);
					return [
						{
							value: sortedUniqueMatchesPlayed[sortedUniqueMatchesPlayed.length - 1],
							label:
								sortedUniqueMatchesPlayed[
									sortedUniqueMatchesPlayed.length - 1
								].toString(),
						},
					];
				} else {
					setIsOnlyOneMatch(false);
					setLastSeasonMatch(matchesPlayed);
					return Array.from({ length: matchesPlayed }, (_, i) => {
						if ((i + 1) % 5 === 0 || i === 0 || i === matchesPlayed - 1) {
							return {
								value: i + 1,
								label: (i + 1).toString(),
							};
						}
						return { value: i + 1 };
					}).filter((mark) => mark.label !== "");
				}
			};

			setMarks(marks);
		} catch (error) {
			console.error("Error fetching data: ", error);
		}
	};

	const filterData = (value, data = originalData) => {
		const filteredData = [...data].filter((d) => d.matchesPlayedByTeam === value);

		// Sort by age and playtime, and then by radius in descending order
		filteredData.sort((a, b) => {
			if (a.x !== b.x) {
				return a.x - b.x;
			} else if (a.y !== b.y) {
				return a.y - b.y;
			} else {
				// Sort by radius (r) so that smaller bubbles appear on top
				return b.r - a.r;
			}
		});

		// Update the chart data
		setChartData({
			datasets: [
				{
					data: filteredData,
					backgroundColor: filteredData.map((d) => d.backgroundColor),
				},
			],
		});
	};

	useEffect(() => {
		if (props.data && props.data.length > 0) {
			loadData(props.data, isSmallScreen);
		}
	}, [props.data, isSmallScreen]);

	useEffect(() => {
		if (originalData.length) {
			filterData(sliderValue);
		}
	}, [sliderValue, originalData]);

	if (sliderValue === null || max === null) {
		return <div>Loading...</div>;
	}

	return (
		<Box sx={wrapperBoxStyle}>
			<SeasonDropDown />
			<Box sx={sliderBoxStyle}>
				<Typography
					variant="body2"
					component="span"
					sx={{
						fontWeight: "bold",
						mt: 1,
					}}
				>
					Games
				</Typography>
				<CustomSlider
					aria-label="Matches"
					value={sliderValue}
					onChange={(event, value) => {
						setSliderValue(value);
					}}
					valueLabelDisplay="auto"
					step={1}
					marks={customMarks}
					min={1}
					max={isOnlyOneMatch ? lastSeasonMatch : max}
					disabled={isOnlyOneMatch}
				/>
			</Box>
			{/*
				Important note! Chart.js uses its parent container to update the canvas render and display sizes. 
				This method requires the container to be relatively positioned and 
				dedicated to the chart canvas only. Responsiveness can then be achieved by setting 
				relative values for the container size
			*/}
			<Box
				sx={{
					position: "relative",
					height: "100%",
					width: "100%",
					minWidth: "40vh",
					maxWidth: "70vh",
					aspectRatio: 1,
					alignSelf: "center",
				}}
			>
				<Bubble
					data={chartData}
					options={chartOptions}
					plugins={[datalabels]}
					ref={chartRef}
				/>
			</Box>
			<ColorGradient />
		</Box>
	);
};

export default TransferKPI;
