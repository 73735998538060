import React, { useEffect, useMemo, useState } from "react";
import { Check, Delete, Inbox } from "@mui/icons-material";
import { Badge, Box, CircularProgress, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { auth0Service } from "../Util/Auth0Client";
import ModalWithTitle from "./Modal/ModalWithTitle";
import CustomTable from "./Table";

const NoteWindow = observer(() => {
	const [noteWindowOpen, setNoteWindowOpen] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const fetchData = async () => {
		setLoading(true);
		setError(null);

		try {
			const token = await auth0Service.getToken();
			const header = { Authorization: `Bearer ${token}` };
			const response = await fetch(`/api/errorreports`, {
				headers: header,
				credentials: "include",
				method: "GET",
			});
			const reportsData = await response.json();

			setData(reportsData);
		} catch (error) {
			console.error("Something went wrong");
			setError(error.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const reports = useMemo(() => {
		return data?.map((report) => ({
			ErrorReportId: report.ErrorReportId,
			ClubName: report.ClubName,
			Name: `${report.FirstName} ${report.LastName}`,
			DateOfBirth: report.DateOfBirth,
			Foot: report.Foot,
			Height: report.Height,
			Weight: report.Weight,
			BirthplaceArea: report.BirthplaceArea,
			PassportArea: report.PassportArea,
			PositionName: report.PositionName,
			ContractExpiration: report.ContractExpiration,
			UserInsert: report.UserInsert,
		}));
	}, [data]);

	const [tableColumns] = useState([
		{ field: "ClubName", headerName: "Club" },
		{ field: "Name", headerName: "Name" },
		{ field: "PositionName", headerName: "Position" },
		{ field: "Height", headerName: "Height" },
		{ field: "Weight", headerName: "Weight" },
		{
			field: "ContractExpiration",
			headerName: "Contract Expiration",
			customContent: (field) => dayjs(field).format("YYYY-MM-DD"),
		},
		{ field: "Foot", headerName: "Foot" },
		{ field: "BirthplaceArea", headerName: "Birthplace" },
		{ field: "PassportArea", headerName: "Nationality" },
		{
			field: "DateOfBirth",
			headerName: "Date of Birth",
			customContent: (field) => dayjs(field).format("YYYY-MM-DD"),
		},
		{ field: "UserInsert", headerName: "Requested by" },
		{
			field: "Accept",
			headerName: "Accept",
			headerInfo: "When accepting changes, the player will be updated accordingly.",
			customContent: (field, row) => (
				<IconButton onClick={() => handleAccept(row.ErrorReportId)}>
					<Check />
				</IconButton>
			),
		},
		{
			field: "Remove",
			headerName: "Remove",
			headerInfo: "Delete the report without accepting changes. ",
			customContent: (field, row) => (
				<IconButton onClick={() => handleDelete(row.ErrorReportId)}>
					<Delete />
				</IconButton>
			),
		},
	]);

	function notificationsLabel(count) {
		if (!count) {
			return "No reports";
		}
		if (count > 99) {
			return "More than 99 reports";
		}
		return `${count} reports`;
	}

	const noteClick = () => {
		setNoteWindowOpen(true);
	};

	const noteClose = () => {
		setError(null);
		setNoteWindowOpen(false);
	};

	const handleDelete = async (errorReportId) => {
		setError(null);

		if (!errorReportId) return;

		try {
			const token = await auth0Service.getToken();
			const header = {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			};
			const response = await fetch(`/api/errorreports?errorreportid=${errorReportId}`, {
				method: "DELETE",
				headers: header,
			});

			if (!response.ok) {
				throw new Error("Failed to delete report");
			}
		} catch (error) {
			setError(error.message);
			console.error(error.message);
		} finally {
			fetchData();
		}
	};

	const handleAccept = async (errorReportId) => {
		setError(null);

		if (!errorReportId) return;

		try {
			const token = await auth0Service.getToken();
			const header = {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			};
			const response = await fetch(`/api/errorreports?errorreportid=${errorReportId}`, {
				method: "PATCH",
				headers: header,
			});

			if (!response.ok) {
				throw new Error("Failed to accept report");
			}
		} catch (error) {
			setError(error.message);
			console.error(error.message);
		} finally {
			fetchData();
		}
	};

	return (
		<Box>
			<IconButton onClick={noteClick}>
				<Badge
					badgeContent={data?.length}
					color="primary"
					aria-label={notificationsLabel(data?.length)}
				>
					<Inbox />
				</Badge>
			</IconButton>
			<ModalWithTitle
				open={noteWindowOpen}
				title="Reports"
				onClose={noteClose}
				maxWidth={"xxxl"}
			>
				<Typography sx={{ mt: 0 }}>
					If a squad player's data is incorrect, the club can report it. Admin users can
					view the report here.
				</Typography>
				{error && (
					<Typography variant="body2" sx={{ fontWeight: 700, color: "error.main" }}>
						{error}
					</Typography>
				)}

				{loading ? (
					<div>
						<CircularProgress />
					</div>
				) : reports?.length > 0 ? (
					<>
						<CustomTable tableData={reports} tableColumns={tableColumns} />
					</>
				) : (
					<Typography sx={{ fontStyle: "italic" }}>
						No reports currently submitted.
					</Typography>
				)}
			</ModalWithTitle>
		</Box>
	);
});

export default NoteWindow;
