import React, { useState } from "react";
import { Button, styled, Tooltip } from "@mui/material";
import {
	Document,
	Font,
	Image,
	Page,
	pdf,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";
import * as htmlToImage from "html-to-image";
import logo from "../Logos/GoalunitLogo_NavyHorisontal.png";
import logoCover from "../Logos/GoalunitLogo_NavyHorisontalCover.png";
import { impersonationStore } from "../State/ImpersonationStore";
import montserratBold from "../Style/Montserrat-Bold.ttf";
import montserratRegular from "../Style/Montserrat-Regular.ttf";

// Tooltip title
const toolTipTitle =
	"Make sure to always maximize your browser window and refresh the page before generating the PDF report";

// Register Montserrat fonts
Font.register({
	family: "Montserrat",
	fonts: [
		{ src: montserratRegular, fontWeight: "normal" },
		{ src: montserratBold, fontWeight: "bold" },
	],
});

// Define styles for the PDF
const styles = StyleSheet.create({
	coverPage: {
		backgroundColor: "#FFFFFF",
		position: "relative",
		height: "100%",
	},
	coverContent: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: 40, // Increased padding for better spacing
	},
	coverLogo: {
		width: 200, // Slightly reduced for better proportion
		height: 50,
		marginTop: 50,
		marginBottom: 50, // Increased margin for better spacing
	},
	coverTitle: {
		fontFamily: "Montserrat",
		fontWeight: "bold",
		fontSize: 36, // Increased font size
		textAlign: "center",
		marginBottom: 20, // Increased margin
	},
	coverSubtitle: {
		fontFamily: "Montserrat",
		fontSize: 20, // Increased font size
		textAlign: "center",
	},
	page: {
		flexDirection: "column",
		backgroundColor: "#FFFFFF",
		padding: 20,
	},
	header: {
		position: "absolute",
		top: 10,
		left: 0,
		right: 0,
		paddingHorizontal: 20,
		paddingBottom: 5,
		borderBottomWidth: 0.5,
		borderBottomColor: "#000",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
		zIndex: 1, // Ensure header is on top
	},
	logo: {
		width: 80,
		height: 20,
	},
	contentContainer: {
		marginTop: 50, // Adjust this value to ensure content starts below the header
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	title: {
		fontFamily: "Montserrat",
		fontWeight: "bold",
		fontSize: 30,
		textAlign: "center",
		marginBottom: 10,
	},
	imageContainer: {
		width: "90%",
		height: "80%",
		justifyContent: "center",
		alignItems: "center",
	},
	image: {
		objectFit: "contain",
		maxWidth: "100%",
		maxHeight: "100%",
	},
	description: {
		fontFamily: "Montserrat",
		fontWeight: "normal",
		fontSize: 12,
		textAlign: "center",
		marginTop: 10,
	},
	bold: {
		fontFamily: "Montserrat",
		fontSize: 14,
		fontWeight: "bold",
		textAlign: "center",
	},
	clubShield: {
		width: 100,
		height: 100,
		marginBottom: 20,
		objectFit: "contain",
	},
});

const CoverPage = ({ title, subtitle, clubImage }) => (
	<Page size="A4" orientation="landscape" style={styles.coverPage}>
		<View style={styles.coverContent}>
			{clubImage && <Image style={styles.clubShield} src={clubImage} />}
			<Text style={styles.coverTitle}>{title}</Text>
			<Text style={styles.coverSubtitle}>{subtitle}</Text>
			<Image style={styles.coverLogo} src={logoCover} />
		</View>
	</Page>
);
// A reusable component for each page in the PDF
const GraphPage = ({ title, imageData, descriptionParts }) => (
	<Page size="A4" orientation="landscape" style={styles.page}>
		<View style={styles.header} fixed>
			<Image style={styles.logo} src={logo} />
		</View>
		<View style={styles.contentContainer}>
			<Text style={styles.title}>{title}</Text>
			<View style={styles.imageContainer}>
				<Image style={styles.image} src={imageData} />
			</View>
			<View>
				{descriptionParts.map((line, index) => (
					<View key={index} style={styles.description}>
						<Text style={styles.bold}>{line.label}</Text>
						<Text>{line.text}</Text>
					</View>
				))}
			</View>
		</View>
	</Page>
);

// The complete PDF document structure
const PDFDocument = ({ pages, coverTitle }) => {
	const date = new Date();
	const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
	const subtitle = "Created on " + formattedDate;

	return (
		<Document>
			<CoverPage title={coverTitle} subtitle={subtitle} />
			{pages.map((page, index) => (
				<GraphPage
					key={index}
					title={page.title}
					imageData={page.imageData}
					descriptionParts={page.descriptionParts.map((description) => ({
						label: description.label,
						text: description.text?.replace(/\n\s+/g, " ").trim(),
					}))}
				/>
			))}
		</Document>
	);
};

const PDFDocumentGenerator = async (pages, clubInfo, coverTitle) => {
	return await pdf(
		<PDFDocument pages={pages} clubInfo={clubInfo} coverTitle={coverTitle} />
	).toBlob();
};

// Define the custom button with conditional styling
const CustomButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== "isLoading",
})(({ theme, isLoading, visible }) => ({
	fontFamily: "Montserrat, sans-serif",
	marginTop: theme.spacing(3),
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	backgroundColor: isLoading ? "red" : "#1A5F7A",
	"&:hover": {
		backgroundColor: isLoading ? "darkred" : "#23374C",
	},
}));

// Main component
const ExportPDF = ({ refs, titles, coverTitle }) => {
	const [isLoading, setIsLoading] = useState(false);

	const captureChartsAsImages = async (refs, titles) => {
		const images = [];
		try {
			const fontEmbedCss = await htmlToImage.getFontEmbedCSS(refs.current[0]);

			for (let index = 0; index < refs.current.length; index++) {
				const chartRef = refs.current[index];
				if (chartRef) {
					const base64Image = await htmlToImage.toPng(chartRef, { fontEmbedCss });

					images[index] = {
						title: titles[index],
						imageData: base64Image,
						descriptionParts: [{ label: "", text: "" }], // TODO: add when needed
					};
				}
			}
			return images;
		} catch (error) {
			console.error("Error capturing chart image:", error);
		}
	};

	const handleExportPDF = async () => {
		setIsLoading(true); // Start loading state
		// Simulate a delay of 4 seconds to ensure all data is ready
		await new Promise((resolve) => setTimeout(resolve, 3000));

		const clubInfo = impersonationStore.extractClubInfo();

		const pages = await captureChartsAsImages(refs, titles);
		const pdfBlob = await PDFDocumentGenerator(pages, clubInfo, coverTitle);
		const url = URL.createObjectURL(pdfBlob);
		const link = document.createElement("a");
		link.href = url;
		const date = new Date();
		const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
		const reportName = clubInfo.ClubName
			? `${clubInfo.ClubName}-Report-${formattedDate}.pdf`
			: `Report-${formattedDate}.pdf`;
		link.download = reportName;
		link.click();
		setIsLoading(false);
	};

	return (
		<div>
			<Tooltip title={toolTipTitle}>
				<CustomButton variant="contained" onClick={handleExportPDF} isLoading={isLoading}>
					{isLoading ? "Exporting PDF ..." : "Export Report to PDF"}
				</CustomButton>
			</Tooltip>
		</div>
	);
};

export default ExportPDF;
